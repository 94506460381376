



















































































































































































import TopBar from "@/components/common/TopBar.vue";
import SKREditor from "@/components/common/SKREditor.vue";
import Vue from "vue";
import store from "../../store";
import Component from "vue-class-component";
import { getRouter } from "../../router";
import { Watch } from "vue-property-decorator";
import { callSkrEditor } from "../../helper/utils";
import api from "../../api";
declare const $: any;

@Component({ components: { TopBar, SKREditor } })
export default class SKR extends Vue {
  prevId = 0;
  editorTitle = "SKR Struktur Template Editor";
  routerName: any = "";
  templateName = "";
  nameIssue = false;
  messageStatus = 0;
  selectedYear = "";
  client: any = "";
  // agency = ''
  agencies: any[] = [];
  clients: any[] = [];
  loadedAgencies: any[] = [];
  userToDelete: any = "";
  selectedEmployee: any = "";
  listEmployees: any = "";
  userIdToUnassign: any = "";
  // templateData: any = []
  // templateDataWatch: any = []
  renderComponent: boolean = true;
  constructor() {
    super();
    // this.getAgencies()
    // this.getEmployees()
  }

  get agency() {
    return store.getters.selectedAgency;
  }

  getAgencies() {
    api.getConfigClientMandators().then((res) => {
      this.loadedAgencies = res.data;
      let getAgencies = Object.keys(res.data);
      getAgencies.sort();
      this.agencies = getAgencies;

      if (this.agency == null) {
        store.dispatch("updateSelectedAgency", getAgencies[0]);
      }

      // if(this.agency != '' && this.loadedAgencies.length){
      let selectedAgency = this.agency;
      this.clients = this.loadedAgencies[selectedAgency];
      this.client = this.clients[0] || "";
      // }
    });
  }

  getEmployees() {
    if (this.agency) {
      api
        .getEmployees(this.agency)
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          let listEmp: any = [];
          res.data.forEach((d: any) => {
            if (d[4] == 3) {
              listEmp.push({
                id: d[0],
                username: d[1],
                firstname: d[2],
                lastname: d[3],
                level: d[4],
              });
            }
          });
          this.listEmployees = listEmp;
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  clearOnEsc() {
    // this.dropdownSearch = ''
    //A way to handle Jquery component inside Vuejs
    const _bodySelector = document.querySelector("body");
    if (_bodySelector) {
      _bodySelector.click();
    }
  }

  @Watch("agency")
  onAgencyChange() {
    // console.log('Changed')
    if (this.loadedAgencies.length > 0) {
      // console.log('Agency changed')
      let selectedAgency = this.agency;
      this.clients = this.loadedAgencies[selectedAgency];
      this.client = this.clients[0] || "";
      this.getEmployees();
    }
  }

  @Watch("client")
  onClientChange() {
    // this.getEmployeeMandators()
  }

  setMandator(client: any) {
    localStorage.setItem("client", JSON.stringify(client));
    this.client = client;
    // store.dispatch('updateSelectedClient', client)
  }

  setAgency(agency: any) {
    localStorage.setItem("agency", JSON.stringify(agency));
    store.dispatch("updateSelectedAgency", agency);
  }

  mounted() {
    this.initSortable();
    this.onTopBarDataChange();
    this.routerName = getRouter().currentRoute.name;

    if (this.routerName === "skr_mandant") {
      this.editorTitle = "SKR Auswahl";
    }
    //Support Bootstrap inside vuejs
    // document.body.addEventListener('keyup', e => {
    //   if (e.keyCode === 27) {
    //     this.clearOnEsc()
    //   }
    // })
  }

  get templateData() {
    return store.getters.skrTemplateData;
  }

  // @Watch('templateData', { immediate: true, deep: true })
  // async onTemplateDataChange() {
  //   //templateData
  //   console.log('Watcher')
  //   this.templateDataWatch = this.templateData
  // }
  get skrTemplates() {
    return store.getters.skrTemplates || [];
  }

  get topBarData() {
    return {
      client: store.getters.selectedClient,
    };
  }

  @Watch("topBarData")
  onTopBarDataChange() {
    if (this.routerName === "skr_mandant") {
      this.selectedYear = "";
      this.templateName = "";
    }
    if (!store.getters.isSkrCalled) {
      callSkrEditor();
    }
  }

  initSortable() {
    ($("ol.sortable") as any).nestedSortable({
      forcePlaceholderSize: true,
      handle: "div",
      helper: "clone",
      items: "li",
      opacity: 0.6,
      placeholder: "placeholder",
      revert: 250,
      tabSize: 25,
      tolerance: "pointer",
      toleranceElement: "> div",
      maxLevels: 1,
      isTree: true,
      expandOnHover: 700,
      startCollapsed: false,
      change: function() {},
    });
  }

  addItem() {
    this.messageStatus = 0;
    this.prevId += 1;
    this.templateData.push(["", "", "", "", this.prevId]);
  }

  getId(items: any) {
    if (!items) {
      return;
    }
    for (var i = 0; i < items.length; i++) {
      if (this.prevId < parseInt(items[i][4])) {
        this.prevId = parseInt(items[i][4]);
      }
    }
  }

  save() {
    if (!this.templateName) {
      this.nameIssue = true;
      return;
    }
    this.nameIssue = false;
    const json = $("ol.sortable").nestedSortable("toHierarchy", {
      startDepthCount: 0,
    });
    let convertedVal: any[] = [];
    // console.log(json)
    json.forEach((d: any) => {
      if (Object.keys(d).length > 4) {
        convertedVal.push([d[0], d[1], d[2], d[3], d[4]]);
      }
    });
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .saveUknowSkr(this.templateName, convertedVal)
      .then((res) => {
        this.messageStatus = 1;
        // this.templateDataWatch = [...this.templateData]
        // this.templateData = [...this.templateDataWatch]
        store.dispatch("updateLoading", store.state.loading - 1);
      })
      .catch((err) => {
        this.messageStatus = -1;
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  forceRerender() {
    // Remove my-component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // Add the component back in
      this.renderComponent = true;
    });
  }
  loadTemplate(e: any) {
    this.messageStatus = 0;
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    this.templateName = e.target.value;
    this.nameIssue = false;
    api
      .getUknowSkrTemplate(this.templateName)
      .then((res) => {
        // console.log(res.data)
        this.getId(res.data);
        store.dispatch("updateSkrTemplateData", res.data);
        // this.templateData = res.data
        this.forceRerender();
        store.dispatch("updateLoading", store.state.loading - 1);
      })
      .catch((err) => {
        store.dispatch("updateSkrTemplateData", []);
        // this.templateData = []
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  deleteTemplate() {
    if (this.routerName === "skr_editor_uknow") {
      if (!this.templateName) {
        return;
      }
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .deleteUknowSkr(this.templateName)
        .then((res) => {
          callSkrEditor();
          this.prevId = 0;
          this.templateName = "";
          store.dispatch("updateSkrTemplateData", []);
          // this.templateData = []
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateSkrTemplateData", []);
          // this.templateData = []
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else {
      if (!this.selectedYear) {
        return;
      }
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .deleteMandantSkr(this.topBarData.client, this.selectedYear)
        .then((res) => {
          this.templateName = "";
          this.selectedYear = "";
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }
}
